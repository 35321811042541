@import "_variables";
a.tel-link{
  color: #FFFFFF;
}
#light-logo{
  width: 250px;
  position: absolute;
}
#dark-logo{
  max-height: 60px;
  position: absolute;
}
#whoDrew{
  font-size: .9rem;
  margin-right: 6px!important;
  margin-top: 3px!important;
}
#whoDrew:focus,#whoDrew:active{
  color: $primary_color;
  outline: none;
  text-decoration: none;
}
@media(max-width:767px){
  #header-2 .header-nav-logo{
    padding: 5px!important;
  }
}
.navbar-fixed{
  transition: .5s linear all;
  .navbar-nav{
    li{
      a{
          text-transform: uppercase;
          color: #000;
      }
    }
  }

}
@media(max-width:992px){
  #navbar-menu{
    input[type="text"]{
      padding: 19px 15px 19px 15px;
    }
  }
}
.search-trigger{
  float: right!important;
  position: absolute;
  right: -20px;
  top: 25px;
  a.open,a:focus,a:visited{
    border:none;
    outline: none;
    color: $primary_color;
  }
  a{
    i{
      color: #000000;
      font-weight: 600;
    }
  }
}
.no-background{
  .search-trigger{
    a{
      i{
        color: #FFFFFF;
        font-weight: 600;
      }
    }
  }
}

#searchDropDown{
  visibility: hidden;
  top:20px;
  position: absolute;
  right: 15px;
  z-index: 1000;
  text-align: right;
  .btn{
    margin-bottom: 0;
  }
  input[type="text"]{
    width: 500px;
    max-width: 75%;
    background: rgba(0,0,0,0.2);
    color: #FFFFFF;
    padding: 19px 15px 19px 15px;
    &::placeholder{
      color: #FFFFFF;
    }
  }
}

#subNavContainer{
  box-sizing: border-box;
  overflow-x: auto;
   /* We don't want vertical scrolling */
   overflow-y: hidden;
   /* Make an auto-hiding scroller for the 3 people using a IE */
   -ms-overflow-style: -ms-autohiding-scrollbar;
   /* For WebKit implementations, provide inertia scrolling */
   -webkit-overflow-scrolling: touch;
   /* We don't want internal inline elements to wrap */
   white-space: nowrap;
   /* Remove the default scrollbar for WebKit implementations */
   &::-webkit-scrollbar {
       display: none;
   }
}
.subnav-container{
    transition: all .25s !important;
  width: 100%;
  text-align: center;
  background-color: #DDD;
  @media(max-width: 767px){
    &.fixed{
      position: fixed;
       top: 55px;
       z-index: 7;
    }
    overflow-x: hidden;
    .container{
      ul{
        width:auto;
        float: left;
        padding: 5px 0 10px 0;
        li{
          display: inline;
          width: auto;
          float: none;
        }
      }
    }
  }
}
.subnav-collapse.collapsed
{
  .arrow:after{
    font-family: 'FontAwesome';  /* essential for enabling glyphicon */
    content: "\f078";    /* adjust as needed, taken from bootstrap.css */
    color: #FFFFFF;
    padding-top: 15px;
  }
  .action:before{
    content: "Show";
  }
}
.subnav-collapse{
  .arrow:after{
    font-family: 'FontAwesome';  /* essential for enabling glyphicon */
    content: "\f077";
    color: #FFFFFF;
    padding-top: 10px;
  }
  .action:before{
    content: "Hide";

  }
}

.btn{
  border-radius: 10px;
  padding: 10px 25px;
  text-transform: uppercase;
  @media(min-width: 768px) and (max-width:1199px){
    margin-bottom: 1rem;
  }
}
.btn-white-on-secondary{
  &:hover{
    background-color:darken($secondary_color,10%)!important;
  }
}
.current-series{
  padding: 25px 0;
  iframe{
    max-width: 100%!important;
    border:none;
  }
  h3.series-name{
    text-transform: uppercase;
    color: $body_text_color;
    font-weight: bold;
  }
  h2{
    @media(max-width: 767px){
      margin-top: 10px!important;
    }
  }
}
.back-to-top{
  position: fixed;
  bottom: 25px;
  right: 15px;
  z-index: 100;
  height: 70px;
  width:70px;
  background-color: transparent;
  background: none;
  color: #444444;
  text-decoration: none;
}
.back-to-top i{
  font-size: 60px;
}
.full-screen-billboard-video{
  .action-buttons{
    margin-left: 0;
    .btn{
      word-break: normal;
      white-space: nowrap!important;
    }
  }
}
.full-screen-billboard{
  /*h2{
    margin: 5% 10% 0% 10%;
  }*/
  &.center{
    h3{
      margin-left: 0;
    }
  }
  h3{
    font-size: 1.5rem;
  }
  .action-buttons{
    margin-left: 0;
    float:none!important;
  }
}
.action-billboard{
  .action-buttons{
      width: 100%;
  }
}
.color-footer .widget-title{
  margin-bottom: .6em;
}
.color-footer .widget-title h4{
  font-size: 1.5em;
}
footer i.fa{
  font-size: 1.5em;
}
footer i.fa.fa-twitter, footer i.fa.fa-facebook{
  font-size: 2.5em;
}
footer p, footer span{
  font-size: .8em!important;
}
footer .about-widget img{
  max-width: 100%;
  max-height: auto!important;
  width: 100%;
}
footer .info{
  color: #FFFFFF;
}
.footer{
  background-color: $primary_color;
}
.stay-connected{
  background-color: darken($primary_color,10%);
  padding: 25px 0;
  h3, p{
    color: #FFFFFF;
  }
  h3{
    margin-top: 0;
    text-transform: uppercase;
  }
  p{
    margin-top: 5px;
    margin-bottom: 0;
    font-size: 90%;
  }
  .btn{
    margin-top:15px;
  }
}
.e-news-signup{
  padding: 25px 0;
  background-color: darken($primary_color,10%);
  form{
    width: 100%!important;
    .gform_body{
      width: 66%!important;
      float: left;
      ul.gform_fields{
        float: left;
        width: 100%;
        margin-left: 0;
        padding-left: 0;
        list-style-type: none;
        li{
          float: left;
          width: 45%;
          margin-right: 5%;
          @media(max-width: 767px){
            width: 100%;
            margin-right: 0;
            margin-bottom: 15px;
          }
          label{
            display: none;
          }
        }
      }
      input{
        margin-top: 2!important;
        padding: 4px 10px;
        @media(max-width: 767px){
          margin-top:10px!important;
        }
      }
      @media(max-width: 767px){
        width: 100%!important;
      }
    }
    .gform_footer{
      width: 33%!important;
      float: left;
      @media(max-width: 767px){
        width: 100%!important;
      }
      .button{
        width: 100%!important;
        background-color: $secondary_color!important;
        border-color: $secondary_color!important;
      }
    }
  }
}
.one-section{
  background-color: $primary_color;
  padding: 25px 0;

  h3, p{
    color: #ffffff;
    letter-spacing: 2px;
  }
  p{
    font-size: 1.3rem;
  }
  h3{
    text-transform: uppercase;
    margin-bottom: 5px;
    font-weight: bold;
  }
}
.details{
    background-color: darken($primary_color,10%);
    padding: 25px 0;
    p{
      color: #FFFFFF;
      margin-top: 0px;
      margin-bottom: 0px;
      font-size: 1rem;
    }
    .btn{
      margin-top: 10px;
    }
    .address-social{
      text-align: right;
      @media(max-width: 767px){
        text-align: left;
      }
      .social-links{
        margin-top: 10px;
        .social-link{
          color: #FFFFFF;
          font-size: 1.5rem;
          padding: 0 0 0 10px;
        }
      }
    }
}
.footer-navigation{
  border-top: 2px lighten($primary_color,5%) solid;
  background-color: darken($primary_color,20%);
  ul{
    li{
      a{
        color: #FFFFFF;
        font-size: 1rem;
        &:hover{
          background-color: transparent;
          color:#ffffff;
          text-decoration: underline;
        }
      }
    }
  }
  .layout-2{
    ul{
      display: inline;
      li{
        float: left;
        display: inline;
      }
    }
  }
  .copyright{
    color: #FFFFFF;
    font-size: .8rem;
  }
}
.social-share{
  padding: 20px 0;
}
.sermon-share{
  float: left;
  width: 75%;
  margin-left: 25px;
  margin-top: 20px;
  h4{
    margin-right: 15px;
    margin-top: 5px;
  }
}
#backTop{
  position: fixed;
  right:2%;
  top: 90%;
  font-size: 2.4rem;
  z-index: 1000;
  color: #FFFFFF;
  i{
    text-shadow: 0px 2px 2px rgba(0, 0, 0, .75);
  }
}
/*footer .gform_wrapper{
  .gform_footer .btn{
    background-color: #FFF!important;
    color: $primary_color !important;
    border-color: #ffffff;
    padding: 10px 35px!important;
    span{
      font-size: 1rem!important;
      line-height:1rem;
    }
  }

  .gform_footer .btn:hover{
      background-color: $secondary_color !important;
      border-color: $secondary_color !important;
      text-shadow: 0px 0px 3px #666;
      color: white !important;
  }

  input[type="text"]{
    width:100%!important;
    font-size: 1rem!important;
    padding: 5px!important;
    color: $body_text_color!important;

  }
  .gfield_label{
    display: none!important;
  }
}*/
.gform{
  select{
    height: 42px;
    border-color: #eee!important;
    border-radius: 0!important;
  }
}
.gform_footer .btn{
  background-color: $primary_color !important;
  color: #FFFFFF;
  border-color: #ffffff;
  padding: 10px 35px!important;
  span{
    font-size: 1rem!important;
    line-height:1rem;
  }
}
.gform_wrapper .gform_body .gform_fields input{
 color:#000000!important;
}

@media(max-width:767px){
  .full-screen-billboard{
    background-attachment: scroll !important;
    h2{
      margin: 30% 0 0 0;
    }
  }
  #light-logo{
    width: 175px;
  }

  .navbar-fixed-top .navbar-collapse, .navbar-fixed-bottom .navbar-collapse {
    max-height: 425px;
  }
  .single-event #header-2 .header-nav-button-line {
    background-color: #474B59;
  }
}

#eventSearch{
  padding: 10px 30px!important;
  height: 50px;
  &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color:#000!important;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color:#000!important;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color:#000!important;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color:#000!important;
    }
}

.header-overlay{
  background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%,  rgba(255,255,255,0.75) 19%, rgba(255,255,255,0.75) 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,0.75) 19%,rgba(255,255,255,0.75) 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,0.75) 19%,rgba(255,255,255,0.75) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#a6ffffff',GradientType=0 ); /* IE6-9 */
}
.title-image-back{
  h1{
    font-weight: 400!important;
  }
  background-size: cover!important;
  height: 175px;
  .tag{
    border: 0;
    background: rgba(0,0,0,0.5);
    padding: 5px 20px;
    width: 100%;
    bottom: 0;
    max-width: 100%;
    left: 0;
    padding-left: 5%!important;
  }
   h2{
    font-size: 50px;
    color: #FFFFFF;
    text-shadow: 0px 0px 5px #000;
    letter-spacing: 0px;
    margin: 0;
    text-transform: uppercase;
    font-weight: bold;
  }
  @media(max-width:767px){
    height:150px;
    .tag{
      padding: 10px;
      left: 0%;
      right: 5%;
    }
    h2{
      font-size: 28px;
      line-height: 26px;
    }
    h4{
      font-size: 16px;
      line-height: 20px;
    }
  }
}


.no-margin-grid-block .block.has_link, .no-margin-grid-block .block{
    padding: 0;
}

.no-margin-grid-block {

  .block {
    &.no_text{
      .content{
        background-color:transparent;
      }
    }
    .content{
      background-color: rgba(0,0,0,.5);
    }
      &.has_link{
        .content{
          background-color: rgba(0,0,0,.5);
        }
      }
      .content {
        width: 100%;

        padding: 15px 20px;
        h3{
            background-color: transparent;
            padding: 0;
            line-height: 2.0rem;
            font-size: 1.9rem;
            text-transform: uppercase;
            span{
              background-color: transparent;
              background: transparent;
                -webkit-box-decoration-break: clone;
                box-decoration-break: clone;
            }
        }
        p{
            padding: 0px;
            margin-top: 0px;
            font-size: 1.4rem;
            line-height: 1.1em;
            background-color: transparent;
        }
      }

  }
}


.full-screen-billboard-video{
    background-color: rgba(0,0,0,.5);
    @media(min-width: 768px){
      min-height: 600px;
    }
    div.video-div{
      @media(min-width: 768px){
        min-height: 600px;
      }
    }
}

@media(max-width:767px){
    #header-2{
        padding: 0px;
    }
}

/* STAFF PAGE */
.staff-container{
  h2.title{
    position: relative;
    overflow: hidden;
    text-align: center;
    margin-bottom: 30px;
    span {
        display: inline-block;
        vertical-align: baseline;
        zoom: 1;
        *display: inline;
        *vertical-align: auto;
        position: relative;
        padding: 0 10px 0 10px;

        &:before, &:after {
            content: '';
            display: block;
            width: 1000px;
            position: absolute;
            top: 0.6em;
            border-top: 2px solid $primary_color;
        }

        &:before { right: 100%; }
        &:after { left: 100%; }
    }
  }
  h3.title{
    position: relative;
    overflow: hidden;
    text-align: center;
    margin-bottom: 30px;
    span {
        display: inline-block;
        vertical-align: baseline;
        zoom: 1;
        *display: inline;
        *vertical-align: auto;
        position: relative;
        padding: 0 10px 0 10px;

        &:before, &:after {
            content: '';
            display: block;
            width: 1000px;
            position: absolute;
            top: 0.6em;
            border-top: 1px solid $primary_color;
        }

        &:before { right: 100%; }
        &:after { left: 100%; }
    }
  }
}

.modal {
  .gfield_label{
    display: none!important;
  }
}
.vertical-alignment-helper {
    display:table;
    height: 100%;
    width: 100%;
}
.vertical-align-center {
    /* To center vertically */
    display: table-cell;
    vertical-align: middle;
}

.gform_wrapper form li br{
  height: 0;
  display: none;
}
.gform_wrapper .gform_body .gform_fields .gfield{
  .gfield_checkbox li label{
    margin-top: 5px;
    margin-left: 10px;
  }
  .gfield_radio li label{
    margin-top: 5px;
    margin-left: 10px;
  }
}
.modal-content {
    /* Bootstrap sets the size of the modal in the modal-dialog class, we need to inherit it */
    width:inherit;
    height:inherit;
    /* To center horizontally */
    margin: 0 auto;
}
.modal-body{
  padding: 25px;
}
.post-type-archive-staff{
  .col-md-3{
    min-height: 480px;
    @media(max-width: 1200px){
      min-height: 440px;
    }
    @media(max-width: 992px){
      min-height: 480px;
    }
    h3{
      margin: 5px 0;
    }
  }
}
.content-columns{
  &.center{
    .action-buttons{
      float: none!important;
      text-align: center;
    }
  }
  @media(max-width: 991px){
    padding-top: 0px!important;
    padding-bottom: 0px!important;
  }
  .content-column{
    @media(max-width: 991px){
      padding-bottom:20px
    }
  }
  .dark{
    h3 {
      color: $primary_color;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 1.6rem;
    }
  }

  .col-md-4{
    .action-buttons{
      .btn-not-first{
        margin-left: 0;
      }
      .btn{
        margin: 5px 0;
      }
    }
  }
}


.half-width-content-image-bg .content-container{
    @media(min-width:768px){
        background-color: rgba(255, 255, 255, .8);
        padding: 40px;
        margin: 20px 0px;
        border-radius: 5px;
        box-shadow: 0px 0px 5px #AAA;
  }
}

.btn, .gform_button{
    transition: all .25s !important;
}

.gform_button{
  background-color: $primary_color !important;
  color: #FFF !important;
  border-color: $primary_color;
  padding: 10px 35px !important
}

.btn:hover, .gform_button:hover{
    background-color: $secondary_color !important;
    border-color: $secondary_color !important;
    text-shadow: 0px 0px 3px #666;
    color: white !important;
}

#featuredSermon{
  .video-wrapper
    {
      padding-top: 10px;
      iframe{
        border:none!important;
      }
    }
}
/* STORIES */
.single-story{
  .title-image-back{
    height: 100px;
    .tag{
      display: none!important;
    }
  }
}
.story{
  .title{
    @media(min-width: 768px){
      padding-left: 0;
    }
  }
  h1{
    color: $body_text_color;
    float: left;
    margin-top: 0;
  }
  i.fa{
    font-size: 22px;
    border: 3px $body_text_color solid;
    padding: 3px;
  }
  .story-container{
    background-color: #eee;
    padding: 50px 75px;
    img{
      display: block;
      max-width: 100%;
      height: auto!important;
      padding: 2rem;
      @media(max-width: 767px){
        padding: 1rem 0;
      }
    }
  }
}

.featured-story{
  margin: 1rem 0;
  h2{
  }
  i.fa{
    font-size: 20px;
    border: 3px $body_text_color solid;
    padding: 3px;
  }
}
.story-polaroid{
  .polaroid{
    i.fa{
      position: absolute;
      top:10%;
      right:9%;
      font-size: 20px;
      color: #FFF;
      border: 3px #FFF solid;
      padding: 3px;
    }
  }
  .date{
    font-size: 1rem;
    color: $body_text_color;
  }
  a:hover{
    text-decoration: none;
  }
}

#searchDropDown .form-control:focus {
    border-color: #FFF;
    outline: 0;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(255,255,255,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(255,255,255,.6);
}

.action-billboard .action-buttons .btn {
  margin-bottom: 10px;
}

$neighborhood: #FF7322;
$network: #0085CA;
$need: #B7BF10;


.goco-header{
  padding: 15px;
  .content{
    width: 90%;
    float: left;
    @media(max-width: 991px){
      width: 85%;
    }
    @media(max-width: 767px){
      width: 80%;
    }
    @media(max-width: 480px){
      width: 75%;
    }

    .title-description{
      float: left;
      margin-top: 12px;
      @media(max-width: 991px){
        margin-top: 10px;
      }
      @media(max-width: 767px){
        margin-top: 0px;
      }

      h2{
        color: #FFFFFF;
        margin: 0;
        font-weight: bold;
        @media(max-width: 767px){
          font-size: 1.4rem;
        }
      }
      p{
        color: #FFFFFF;
        @media(max-width: 767px){
          font-size: 1.1rem;
        }
      }
    }
  }
  .image{
    width: 10%;
    float: left;
    @media(max-width: 991px){
      width: 15%;
    }
    @media(max-width: 767px){
      width: 20%;
    }
    @media(max-width: 480px){
      width: 25%;
    }
  }

  .goco-icon{
    float: left;
    max-width: 70%;
    @media(max-width: 767px){
      max-width: 80%;
    }
  }
  &.need{
    background-color: $need;
  }
  &.network{
    background-color: $network;
  }
  &.neighborhood{
    background-color: $neighborhood;
  }

}
.goco-options{
  @media(max-width: 767px){
    margin: 15px 0;
  }
  .option{
    padding: 2rem;
    @media(max-width: 767px){
      padding: 10px 5px;
    }

    .option-link{
      display: block;
      padding: 15px 25px;
      border-radius: 10px;
      @media(max-width: 1099px){
        padding: 15px 10px;
      }
      h3{
        text-align: center;
        color: #FFFFFF;
        margin-top: 10px;
        @media(max-width: 1099px){
          font-size: 1.1rem;
        }
      }
      img{
        max-width: 50%;
        margin: auto;
      }
      &:hover,&.active {
        cursor: pointer;
        box-shadow: inset 0px 0px 15px #333333;
        text-decoration: none;
      }
      &.need{
        background-color: $need;
        &:hover{
          background-color: darken($need,10%);
        }
      }
      &.network{
        background-color: $network;
        &:hover{
          background-color: darken($network,10%);
        }
      }
      &.neighborhood{
        background-color: $neighborhood;
        &:hover{
          background-color: darken($neighborhood,10%);
        }
      }
    }

  }
}
.btn-goco{
  img{
    width: 50px;
    padding-right: 10px;
    @media(max-width: 767px){
      padding-right: 5px;
    }
  }
  span{
    color: #FFFFFF;
    font-size: 1.5rem;
    line-height: 50px;
    vertical-align: middle;
  }

  &.btn-neighborhood{
    background-color: #ff7322;
    &:hover{
      background-color: darken($neighborhood, 10%)!important;
      border-color: darken($neighborhood, 10%)!important;
    }
  }
  &.btn-network, &.btn-interest{
    background-color: #0085CA;
    &:hover{
      background-color: darken($network, 10%)!important;
      border-color: darken($network, 10%)!important;
    }
  }
  &.btn-need,&.btn-needs{
    background-color: #B7BF10;
    &:hover{
      background-color: darken($need, 10%)!important;
      border-color: darken($need, 10%)!important;
    }
  }

  &.active{
    box-shadow: inset 0 0 5px rgba(0,0,0,0.5);
  }

}

.goco-search-section{
  background-color: #e9e9e9;
  padding: 2.5rem 2rem;

  .btn-goco{
    color: #FFFFFF!important;
    text-align: center;
    margin-bottom: 10px;
  }
}

.goco-result{
  background: rgba(0,0,0,0.05);
  border-radius: 8px;
  padding: 10px 15px;
  margin-bottom: 25px;
  h3{
    font-weight: bold;
    margin-top: 10px;
  }
  .distance{
    font-style: italic;
  }
  @media(max-width: 1199px){
    .btn{
      font-size: 1rem;
    }
    .btn-not-first{
      margin-left: 0;
    }
  }
}

.modal {
  text-align: center;
  padding: 0!important;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: -4px;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
.modal-backdrop{
  background-color: transparent;
  background: rgba(255,255,255,0.8);
}

#staffForm{
  position: fixed;
  top:75px;
  left:0;
  margin-left:0;
  &.modal{
    &:before{
      height: 0;
    }
  }
  @media(max-width:767px){
    margin-left: 0;
    left:0;
  }
}
