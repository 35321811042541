@import "_variables";

.header-v1{

  html &{
    margin-top: 0!important;
  }

  &.mobile-header{
    @media(max-width: 1000px){
      display: block;
    }
    @media(min-width: 1001px){
      display: none;
    }
  }
  &.desktop-header{
    z-index: 1000;
    position: absolute;
    width: 100%;
    @media(max-width: 1000px){
      display: none;
    }
    @media(min-width: 1001px){
      display: block;
    }
  }
  padding: 10px 0;
  &.sticky{
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    &.stuck{
      box-shadow: 0px 0px 10px #666;
      .main-navigation{
        li{
          a{
            color: $primary_color;
          }
        }
      }

    }
  }
  .logo-link,.overlay-logo-link{
    display: block;
    @media(max-width: 767px){
      max-width: 50%;
      margin: auto;
      img{
        max-height: 55px;
        margin: auto;
      }
    }
    @media(max-width: 1000px){
      max-width: 35%;
      margin: auto;
      img{
        max-height: 65px;
        margin: auto;
      }
      &.right{
        float: right;
      }
      &.left{
        float: left;
      }
    }
  }

  &.transparent{
    &:not(.stuck){
      &.desktop-header .main-navigation > li > a, &.mobile-header .button-container i, &.mobile-header .button-container span, &.mobile-header .button-container{
        color: #FFF;
        text-shadow: 0px 0px 5px #333;
        
      }
    }
  }

  /* DESKTOP NAV */
  @media(min-width:1001px){
    .main-navigation{
      list-style-type: none;
      margin-bottom: 0;
        li{
          display: inline-block;
          &:hover{
            transition: all ease-in-out 0.15s;
            .sub-menu {
                display: block!important;
                transition: all ease-in-out;
            }
          }
          a{
            padding: 10px 30px;
            position: relative;
            display: inline-block;
            &:hover{
              text-decoration: none;
            }
          }
        }
        .sub-menu {
            display: none;
            transition: all ease-in-out;
            position: absolute;
            background-color: #f9f9f9;
            min-width: 200px;
            padding-left: 0;
            box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
            z-index: 1;
            li{
              width: 100%;
              display: block;
              a{
                width: 100%;
                padding: 10px 20px;
                &:hover{
                }
              }
            }
        }
    }
  }

  /* MOBILE NAVIGATION */

  .button-container,.overlay-button-container {
    color: $primary_color;

    i, span{
      color: $primary_color;
    }

    position: absolute;
    top: 15px;
    padding: 5px;
    .fa{
      font-size: 1.5rem;
    }
    &.left{
      left: 2%;
    }
    &.right{
      right:2%;
    }

    &#toggle-close{
      position: absolute;
      z-index: 1000;
      .fa{
      }
    }
  }

  .overlay-menu-container {
    position: fixed;
    background: #FFF;
    z-index: 1000;
    visibility: hidden;
    overflow: hidden;
    padding: 10px 0;
    top:0;
    left: 0;
    height: 100%;
    width: 100%;

    nav {
      position: relative;
      padding: 50px 0;
    }
    ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      display: inline-block;
      position: relative;
      height: 100%;
      width: 100%;
      li {
        display: block;
        position: relative;
        &.menu-item-has-children > a{
          &:after{
            font-family: FontAwesome;
            content: "\f105";
            width: 15px;
            float: right;
          }
          &.open{
            &:after{
              font-family: FontAwesome;
              content: "\f107";
              width: 15px;
              float: right;
            }
          }
        }


        a {
          display: block;
          position: relative;
          color: $primary_color;
          font-size: 1.5rem;
          padding: 10px;
          text-decoration: none;
          overflow: hidden;
        }
        .sub-menu{
          display: none;
          width: 100%;
          li{
            padding: 10px 5px;
            a{
              padding: 5px;
            }
          }
        }
      }
    }

    .cta-buttons{
      margin:2rem 0;
    }
  }
  /* END MOBILE NAVIGATION */
}
