
#header-2 {

    border: none;
    margin: 0;
    padding: 5px 20px;
    z-index: 100
}

#header-2 nav {
    max-width: 1140px;
    margin: 0 auto
}
#header-2.navbar-black{
  background-color: #000;
}
#header-2.navbar-white {
    background-color: #FFF;
    border: 1px solid rgba(70, 76, 89, .1);
    border-top: 0px;
}
.navbar-white #light-logo{
  opacity: 0;
}

.navbar-transparent #dark-logo{
  opacity: 0;
}

#header-2.navbar-white li > a {
    /*border: 1px solid #fff;
    background-color: #fff;*/
    color: #474B59
}
#header-2.navbar-transparent li > a{
  font-size: 18px;
  color: #ffffff;
}
#header-2.navbar-black li > a{
  font-size: 18px;
  color: #ffffff;
}

#header-2.navbar-white li>a:focus, #header-2.navbar-white li>a:visited, #header-2.navbar-white li>a:active{
  background-color: transparent!important;
  background: transparent!important;
}
#header-2, #header-2 *{
  transition: .5s linear all;
}

#header-2.navbar-white li > a{
  font-size: 18px;
  color: #000000;
}
#header-2.navbar-transparent li > a:hover{
  background-color: transparent;
}
#header-2.navbar-white li > a:hover, #header-2.navbar-white li > a.active {
    /*border-color: #F0F3F5;
    background: #F0F3F5;*/
    color: #474B59;
}

#header-2.navbar-white .header-nav-button {
    /*background: #fff;*/
}

#header-2.navbar-white .header-nav-button:hover {
  /*  background-color: #474B59*/
}

#header-2.navbar-white .header-nav-button:hover .header-nav-button-line {
    background-color: #474B59;
}

#header-2.navbar-white .header-nav-button-line {
    background-color: #474B59;
}

#header-2.navbar-white .header-nav-navigation-link--outline {
    color: #286090;
    border-color: #286090;
}

#header-2.navbar-white .header-nav-navigation-link--outline:hover {
    color: #fff;
    background-color: #286090;
}

#header-2 .header-nav-button {
    margin-top: 15px;
}

#header-2 .header-nav-button:hover {
    background-color: #333;
}

#header-2 .header-nav-button-line {
    background-color: #fff;
}

#header-2 .header-nav-icon {
    font-size: 22px;
    opacity: .4;
    padding-left: 4px;
    position: relative;
    top: 2px;
}

#header-2 .header-nav-navigation {
    padding: 15px 0 10px;
    list-style-type: none;
}

#header-2 .header-nav-navigation li {
    display: inline;
}

#header-2 .header-nav-navigation-item.open .header-nav-navigation-link,
#header-2 .header-nav-navigation-item.open li > a i {
    color: #fff;
}

#header-2 li > a {
    text-decoration: none;
    /*border: 1px solid #474B59;
    background-color: #474B59;*/
    color: #c2c5cc;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 10px 12px;
    margin-right: 2px;
    -webkit-transition-duration: 0;
    transition-duration: 0;
    font-weight: bold;
}

#header-2 li > a:hover, #header-2 li > a.active {
    -webkit-transition-duration: 0;
    transition-duration: 0;
    /*background: #565B6B;
    border-color: #565B6B;*/
    color: #F0F3F5
}

#header-2 li > a:hover .header-nav-icon, #header-2 .header-nav-navigation-link.active .header-nav-icon {
    opacity: .7
}

#header-2 li > a:active {
    background-color: #393D48
}

#header-2 .header-nav-navigation-link--highlight {
    color: #286090
}

#header-2 .header-nav-navigation-link--highlight:hover {
    color: #8ad4d2
}

#header-2 .header-nav-navigation-link--outline {
    color: #286090;
    border-color: #286090
}

#header-2 .header-nav-navigation-link--outline:hover {
    color: #fff;
    background-color: #286090;
    border-color: #286090
}

#header-2 .header-nav-navigation-dropdown {
    background-color: #fff;
    border: none;
    border-radius: 3px;
    margin: 0
}

#header-2 .header-nav-navigation-dropdown:after {
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    content: "";
    display: inline-block;
    right: 20px;
    position: absolute;
    top: -6px
}

#header-2 .header-nav-navigation-dropdown-link {
    color: #474B59;
    padding: 6px 20px
}

#header-2 .header-nav-navigation-dropdown-link:hover {
    background-color: #e1e7eb
}

#header-2 .header-nav-logo {
    padding: 0px 0
}

.header-margin{
  margin-top: 95px;
}

@media(max-width:767px){
  #header-2 {
      border: none;
      margin: 0;
      padding: 15px 20px;
      z-index: 1000;
  }
  #header-2 .header-nav-logo {
    float: left;
    padding: 10px;
    #dark-logo{
      position: static;
    }
  }
  #header-2.navbar-white .header-nav-button-line:hover{
    background-color: #474B59;
  }
  #header-2 .header-nav-navigation ul{
    margin-left: 0;
  }
  #header-2 .header-nav-navigation li {
      display:block;
      text-align: right;
      border-bottom: 1px #444 solid;
      padding: 5px 0;
  }
  .navbar-fixed-top.navbar-transparent .navbar-collapse{
    background:rgba(250,250,250,0.9);
  }
  .navbar-fixed-top.navbar-transparent .navbar-collapse li a{
    color: #000000!important;
  }
  #header-2 .header-nav-navigation {
      padding: 0px 0 0;
  }
  #header-2 .header-nav-button:hover{
    background-color: transparent;
  }
  #header-2.navbar-transparent .header-nav-navigation-link{
    color: #333333;
  }
}
