// Variables
@import "_variables";
@import "_bootstrap";

body.logged-in .navbar-fixed-top{
  margin-top: 32px!important;
}
body.customize-support header{
  margin-top: 27px!important;
}

body {
    font-family: $global_font;
    font-size: 1.2rem;
    color: $body_text_color;
    font-weight: 300;
}

h1, h2, h3, h4, h5{
  color: $primary_color;
  /*font-family: $header_font;*/
}
.pl0{
  padding-left: 0;
}
.pr0{
  padding-right: 0;
}


a{
  color: $primary_color;
}
a:hover{
  color: $primary_color;
}

#header-1.navbar--white .header-nav__navigation-link:hover, #header-1.navbar--white .header-nav__navigation-link.active {
  border-color: darken( $primary_color, 10% );
  background: darken( $primary_color, 10% );
  color: white;
}


.btn-primary {
  text-transform: uppercase;
  font-weight: bold;
  transition: all 0.2s ease 0s;
  background-color: rgba(255,255,255,.3);
  border: 1px solid $primary_color;
  color: $primary_color;
  border-width: 2px;
  -webkit-transition-duration: 0.2s;
   -moz-transition-duration: 0.2s;
}

/*
.btn-primary:hover, .btn-primary:focus, .btn-primary.active.focus, .btn-primary.active:focus, .btn-primary.active:hover, .btn-primary:active.focus, .btn-primary:active:focus, .btn-primary:active:hover, dropdown-toggle.btn-primary.focus, dropdown-toggle.btn-primary:focus, .dropdown-toggle.btn-primary:hover{
  color: $primary_color;
  background-color: rgba(255,255,255,.3);
  box-shadow: 0px 0px 4px rgba($primary_color, .8);
  text-shadow: 0px 0px 4px rgba($primary_color, .2);
  border: 1px solid $primary_color;
}
*/

.btn-lg-text{
  font-size: 2rem
}

a p{
  color: $body_text_color;
}


/*Styleguide Colors*/
.primary-bg{
  background-color: $primary_color;
}
.secondary-bg{
  background-color: $secondary_color;
}

.global-font{
  font-family: $global_font;
}

.brand-font-1{
  font-family: $brand_font_1
}

.brand-font-2{
  font-family: $brand_font_2;
  font-style: italic;
}

.video-wrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}
.video-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.clearfix{
  clear: both;
}
.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 50%;
}
.btn-circle.btn-lg {
  width: 50px;
  height: 50px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
}
.btn-circle.btn-xl {
  width: 70px;
  height: 70px;
  padding: 10px 16px;
  font-size: 24px;
  line-height: 1.33;
}
/*  COLOR    */
/*
 ********************************
 *
 *  Defult CSS
 */

.btn-group .btn-fill, .btn-fill {
  background: $primary_color !important;
  border-color: $primary_color !important;
}
.btn-fill.secondary{
  background: $secondary_color !important;
  border-color: $secondary_color  !important;
}
.btn-group .btn-fill:hover,
.btn-group .btn-fill:focus,
.btn-group .btn-fill:active,
.btn-group .btn-fill.active {
  background: $secondary_color !important;
  border-color: $secondary_color  !important;
}
.btn-fill.secondary:hover,
.btn-fill.secondary:focus,
.btn-fill.secondary:active,
.btn-fill.secondary.active {
  background: $primary_color !important;
  border-color: $primary_color  !important;
}
.btn-group .btn-border:hover,
.btn-group .btn-border:focus,
.btn-group .btn-border:active,
.btn-group .btn-border.active {
  background: $secondary_color !important;
  border-color: $secondary_color !important;
}
.btn-cus {
  padding: 8px !important;
  min-width: 180px;
  border-radius: 50px 50px 50px 50px !important;
  -webkit-border-radius: 50px 50px 50px 50px !important;
  -moz-border-radius: 50px 50px 50px 50px !important;
  -ms-border-radius: 50px 50px 50px 50px !important;
  -o-border-radius: 50px 50px 50px 50px !important;
  margin-right: 15px;
  font-size: 14px;
  margin-bottom: 15px;
  border-width: 2px;
  border-style: solid;
  display: inline-block;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  margin-left: 1px !important;
  transition: all 0.4s;
  -moz-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
}
.btn-cus:hover,
.btn-cus:focus,
.btn-cus:active,
.btn-cus.active {
  transition: all 0.4s;
  -moz-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
}
.btn-group .btn-fill {
  color: #FFFFFF !important;
}
.btn-group .btn-fill:hover,
.btn-group .btn-fill:focus,
.btn-group .btn-fill:active,
.btn-group .btn-fill.active {
  color: #FFFFFF !important;
  outline: none;
}
.btn-group .btn-border {
  background: none;
}
.btn-group .btn-border:hover,
.btn-group .btn-border:focus,
.btn-group .btn-border:active,
.btn-group .btn-border.active {
  color: #FFFFFF !important;
  outline: none;
}


.map-container{
  border-top: 20px rgba($primary_color,0.75) solid;
  border-bottom: 25px rgba($primary_color,0.75) solid;
}
.map-container iframe{
  margin-bottom: -5px;
}

.box-shadow{
  box-shadow: 3px 4px 15px -6px #000;
  -webkit-box-shadow: 3px 4px 15px -6px #000;
  -moz-box-shadow: 3px 4px 15px -6px #000;
}


/* GRAVITY FORMS */

.gform_wrapper {
  form{
     li
     {
       br{
       height: 0;
       display: none;
       }
    }
    input, input[type=text], select, textarea {
  		width:100% !important;
  	}
    .gfield_checkbox {
      br {
        display: none!important;
      }
      li{
        display: inline!important;
      }
      input[type=checkbox]{
        margin-top: 0;
      }
    }
}
}

.gf_full_width{
  width: 100%;
  input{
    width:99.5%!important;
  }
}
.gf_left_half, .gf_right_half{
  width: 49.5%!important;
}
.gf_left_third, .gf_middle_third, .gf_right_third{
  width: 32.5%!important;
}
.hidden-label{
  label{
    display: none!important;
  }
}
