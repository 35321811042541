// Variables
@import "_variables";
.title-image-back {
  position: relative;
  height:250px;
  background-size:cover;
  background-position:center center;


  img{
    min-width: 100%;
  }

  .tag{
    /*background-image: linear-gradient(-48deg, rgba(170, 20, 20, 0.8) 4%, rgba(217, 199, 16, 0.8) 100%);*/
    /*border: 3px solid #FFFFFF;*/
    position: absolute;
    bottom: 25%;
    left: 5%;
    /*padding: 25px;*/
    max-width: 750px;
  }
  h1{
    color: #FFFFFF;

  }
  &.light{
    h1{
      text-shadow: 3px 2px 6px rgba(0, 0, 0, 0.8);
      color: #FFFFFF;
    }
  }
  &.dark{
    h1{
      color: #000;
    }
  }
  h1{
    font-weight: 900;
    font-size: 50px;
    line-height: 53px;
    letter-spacing: 2px;
    margin: 0;
  }


  @media(max-width:767px){
    height:150px;
    .tag{
      padding: 10px;
      left: 5%;
    }
    h2{
      font-size: 28px;
      line-height: 26px;
    }
    h4{
      font-size: 16px;
      line-height: 20px;
    }
  }
}
