// Variables
@import "_variables";

.jarallax {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.action-billboard {
  transition: background-color 0.5s ease;
  background-size: cover;
  .content-container{
    padding: 75px 0px;
  }

  h2{
    font-size: 3rem;
    margin: 0 0 10px;
    line-height: 1em;
    @media(max-width: 767px){
      font-size: 2rem;
    }
  }

  h3{
    font-size: 2rem;
    margin: 0 0 10px 0;
    font-weight: 300;
    line-height: 1em;
    font-family: $global_font;
    @media(max-width: 767px){
      font-size: 1.5rem;
    }
  }
  p{
    margin:0 0 10px 0;
  }

  .action-buttons {
    padding-top: 40px;
    width: 100%;
  }

  &.light{
    h2,h3,p{
      color: #FFF;
      text-shadow: 0px 0px 3px #444;
    }

  }
  &.center{
    *{
      text-align: center;
    }
  }
  &.dark{
    h2{
        color: $primary_color;
    }

    h3,p{
      color: $body_text_color;
    }
  }
}
