@import "_variables";
.polaroid{
  border-radius: 3px;
  box-shadow: 0px 0px 7px #CCC;
  display: block;
  border: 1px solid #BBB;
  padding: 15px;
  margin-bottom: 10px;

  img{
    border: 2px solid #CCC;
    border-radius: 2px;
  }

  h3{
    font-weight: bold;
    margin: 10px 0px 0px 0px;
  }

  a:hover{
    text-decoration-color: $body_text_color;
  }

}
