// Variables
@import "_variables";

.gform_wrapper{
  .gfield{
    &.gf_list_2col,&.gf_list_3col,&.gf_list_4col,&.gf_list_5col,&.gf_list_6col{
      ul.gfield_checkbox, ul.gfield_radio{
        li{
          float:left!important;
          @media(max-width: 767px){
            width: 100%!important;
            margin-bottom: 8px!important;
          }
        }
      }
    }
  }
  .gf_left_half, .gf_right_half, .gf_left_third, .gf_middle_third, .gf_right_third{
    @media(max-width: 767px){
      width: 100%!important;
    }
  }
}

body .gform_wrapper .gform_body .gform_fields .gfield {
  .ginput_container {

  }
    &.third, &.half{
      display: inline-block;
      @media(max-width: 767px){
        width: 100%!important;
      }
    }
    &.third{
      width: 33%;
      input, select, input[type=tel], input[type=email], textarea{
        width: 100%;
      }
    }
    &.half{
      width: 48%;
      display: inline-block;
      input, select, input[type=tel], input[type=email], textarea{
        width: 100%;
      }
    }

  .gfield_label {
    .gfield_required {

    }
  }
  .gfield_description {

  }
  input[type=text] {

  }
  textarea {

  }
  select{

  }
  .gfield_radio{

    li{

      input[type=radio]{

      }
      label{
        max-width: 95%!important;
      }
    }
  }
  .gfield_checkbox {
    li{
      input[type=checkbox]{

      }
      label{
          max-width: 95%!important;
      }
    }
  }
  .ginput_complex {

    .ginput_left {

      label{

      }
    }
    .ginput_right{

      label{

      }
    }
    .ginput_full{

      label{

      }
    }
  }
  .name_first {

    label{

    }
    input{

    }
  }
  .name_last{

    label{

    }
    input{

    }
  }
  input[type=email] {

  }
  input[type=tel] {

  }
  /* ADDRESS */
  .address_line_1 {
    input{

    }
    label{

    }
  }
  .address_line_2 {
    input{

    }
    label{

    }
  }
  .address_city {
    input{

    }
    label{

    }
  }
  .address_state {
    input{

    }
    label{

    }
  }
  .address_zip {
    input{

    }
    label{

    }
  }
  .address_country {
    select{

    }
    label{

    }
  }
  /*DATE/TIME*/
  .gfield_time_hour {
    input{

    }
    label{

    }
  }
  .gfield_time_minute {
    input{

    }
    label{

    }
  }
  .gfield_time_ampm{
    select{

    }
  }
  .datepicker {

  }
  .gfield_input_datepicker_icon {

  }
  .gfield_date_month {
    input{

    }
    label{

    }
  }
  .gfield_date_day {
    input{

    }
    label{

    }
  }
  .gfield_date_year {
    input{

    }
    label{

    }
  }


  /* FILES */
  .gform_fileupload_multifile {

  }
  .gform_drop_area {

  }
  .gform_button_select_files {

  }


  /* SECTIONS*/
  .gsection {
    .gsection_title {

    }
    .gsection_description {

    }
  }
}
