.no-margin-grid-block {
  .block{
    position: relative;
    top: 0;
    left: 0;
    height: 100%;
    padding: 25px;
  }
  .block.has_link{
     background-color: rgba(0, 0, 0, 0.25);
     position: relative;
     top: 0;
     left: 0;
     height: 100%;
     padding: 25px;
     transition: all 0.2s ease 0s;
  }
  .block.has_link.active{
    background-color: rgba(0, 0, 0, .05);
  }

  .block.has_link.active *{
    text-shadow: 1px 1px 2px #333;
  }
  .block h3, .block p{
    margin:0;
    color: #FFFFFF;
  }
  .block h3{
    font-size: 1.8em;
    font-weight: 900;
    background-color: rgba(0,0,0,.6);
    padding: 5px 10px;
    display: inline;
  }
  .block p{
    clear: both;
    font-size: 1.2em;
    margin-top: 15px;
    background-color: rgba(0,0,0,.6);
    padding: 5px 10px;
    bottom: 30px;
  }

  .block .content{
    position: absolute;
    bottom: 30px;
  }

  a *{
    color: #FFF;
  }
}

.no-margin-grid-block:hover{
  a *{
    /*text-decoration: underline;*/
    text-decoration-color: #FFF;
  }
}
