// Variables
@import "_variables";

.full-screen-billboard{
  height: auto;
  text-align: center;
  padding-top: 25vh;
  padding-bottom: 25vh;
  @media(max-width: 767px){
    padding-top: 15vh;
    padding-bottom: 15vh;
  }
  &.left{
    h1, h2,h3{
      text-align: left;
    }
    .title-image > img{
      float:left;
    }
  }
  &.center{
    h1, h2,h3{
      text-align: center;
    }
    .title-image > img{
      margin:auto;
    }
  }
  &.right{
    h1, h2,h3{
      text-align: right;
    }
    .title-image > img{
      float:right;
    }
  }
  h2{
    margin: 0% 10%;
    margin-bottom: 0;
    //padding: 5%;
    padding-bottom: 1%;
    padding-left: 0%;
    text-align: left;
    font-weight: 900;
    font-size: 3.5rem;
  }

  &.light h2{
    color: #FFFFFF;
    border-color: #FFFFFF;
    text-shadow: 3px 2px 6px rgba(0, 0, 0, 0.8);
  }
  &.light h3{
    color: #FFFFFF;
    text-shadow: 3px 2px 6px rgba(0, 0, 0, 0.8);
  }
  &.dark h2{
    color: #000000;
    border-color: #000000;
  }
  &.dark h3{
    color: #000000;
  }

  .action-buttons{
    margin-left: 10%;
    margin-top: 5vh;
  }

  h3{
    text-align: left;
    padding-left: 0%;
    margin-left: 10%;
    font-size: 2.5rem;
  }
  @media(max-width:767px){
    h3{
      margin-left: 0%;
    }
    h2{
      //margin: 20% 0;
      margin: 0% 0;
      margin-bottom: 0;
    }
    .action-buttons{
      margin-left: 0%;
      margin-top: 5vh;
    }
  }

}
