@import "./variables";

.single-event{
  header{
    background-color: #999!important;
    #light-logo{
      display: none;
    }
    #dark-logo{
      display: block;
      opacity: 1;
    }
    .nav > li > a {
      color: #000000!important;
    }
  }
  .btn-scroll{
    width: auto;
    float:left;
    margin-right: 15px;
  }
  #header{
    height: auto;

    .header-overlay.overlay-color{
      padding-top: 5rem;
      padding-bottom: 5rem;
      height: 100%;
      border-bottom: 3px #FFF solid;
      @media(max-width:767px){
        padding-top: 3rem;
        padding-bottom: 3rem;
      }
      h1{
        font-size: 4rem;
        @media(max-width: 767px){
          font-size:2.8rem;
        }
        @media(max-width: 480px){
          font-size: 2.4rem;
        }
      }
      h1, h2, p, .count_down{
        color: $body_text_color;
      }
      .count_down{
        padding: 2rem 0;
      }
        .time .counter{
          float: left;
          font-size: 2.4rem;
          width:100%;
          text-align: center;
          @media(max-width: 767px){
            font-size: 1.8rem;
          }
          @media(max-width: 480px){
            font-size: 1.6rem;
          }
        }
        .time .title{
          float: left;
          font-size: 1.2rem;
          width:100%;
          text-align: center;
          @media(max-width: 767px){
            font-size: 1rem;
          }
          @media(max-width: 480px){
            font-size: .75rem;
          }
        }

    }
  }
}

/*
 ********************************
 *
 *  Register CSS
 *
 */
.input-group .btn-fill {
  background-color: #98d361;
}
.input-group .btn-fill:hover {
  background-color: #71b532;
}
.registration-form li.gfield{
  width: 50%;
  float:left;
  clear: none!important;
}
.registration-form input[type="text"],.registration-form input[type="email"],.registration-form input[type="tel"]{
  background-color: rgba(255, 255, 255, 0.25);
  padding: 8px 5px!important;
  border-radius: 8px;
  border: 1px $body_text_color solid;
  color: #FFFFFF;
  width: calc(100% - 20px)!important;
}
.gform_wrapper label{
  /*display: none!important;*/
}

.registration-form .gform_button.button{
  padding: 8px !important;
  min-width: 180px;
  font-size: 14px;
  background-color: #E03C31;
  color: #ffffff;
  border-color: #E03C31;
  border-radius: 30px;
  border-width: 1px;
  border-color: transparent;
  word-wrap: break-word !important;
  white-space: normal !important
}
.registration-form .gform_button.button:hover{
  background-color: #BA160B;
  color: #FFFFFF;
  border-color: transparent;
}


.pera .p {
  color: #FFF;
}

.register-background .overlay-color{
  background-color: #FFF;
}

.register-body h3{
  color: #333;
}





/*
******************************************
*
*   IMAGE HOVER EFFECT CUSTOM CSS
*
*/
.view-first .mask {
  background-color: rgba(152, 211, 97, 0.5);
}
.mask ul li a:hover {
  background-color: transparent !important;
}
/*
******************************************
*
*   NICE SCROLL CUSTOM CSS
*
*/
.nicescroll-rails > div {
  background-color: #98d361 !important;
}
/*
******************************************
*
*   FLEX SLIDER CSS
*
*/
a.next:hover,
a.prev:hover {
  background-color: #98d361 !important;
  border-color: #98d361;
}
/*
******************************************
*
*   PRELOADER CSS
*
*/
.bubblingG span {
  background: #98d361;
}
