// Variables
@import "_variables";
.sidenav {
    height: 100%; /* 100% Full-height */
    width: 0; /* 0 width - change this with JavaScript */
    position: fixed; /* Stay in place */
    z-index: 1100; /* Stay on top */
    top: 0;
    right: 0;
    background-color: $secondary_color; /* Black*/
    overflow-x: hidden; /* Disable horizontal scroll */
    padding-top: 60px; /* Place content 60px from the top */
    transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
    .nav{
      margin: 55px 15px;
      li{
        min-width: 250px;
      }
    }

    a {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 25px;
        text-transform: uppercase;
        color: $primary_color;
        display: block;
        transition: 0.3s;
        text-align: right;
    }
    a:hover, .offcanvas a:focus{
        background: transparent;
        background-color: transparent;
    }
    .closebtn {
        position: absolute;
        top: 0;
        right: 25px;
        font-size: 36px;
        margin-left: 50px;
    }
    @media screen and (max-height: 767px) {
      padding-top: 15px;
       a {font-size: 18px;}
    }

}
