// Variables
@import "_variables";
@import "_image";
@import "_center-block";
.content-columns{
  padding-top: 50px;
  padding-bottom: 50px;
  @media(max-width: 767px){
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .content-column{
    @media(max-width: 767px){
      padding-bottom:20px;
      padding-top: 20px;
    }
  }
  img{
    @include img-responsive();
    &.aligncenter{
      @include center-block();
    }
  }
  h3{
    @media(max-width:767px){
      margin-top: 10px!important;
    }
    @media(min-width: 768px){
      margin-top: 0px;
    }
  }
  .light{
    h1,h2,h3,p{
      color: #FFFFFF;
    }
  }
  .dark{
    h1,h2{
      color: $primary_color;
    }
    h3,p{
      color: $body_text_color;
    }
  }
}
