@import "_variables";
.post-type-archive-classroom{
  .room{
    float: left;
    background-color: #eee;
    height: 100%;
    padding: 15px 20px;
    margin: 15px;
    transition: background-color 0.25s ease;
    h2{
      margin-top: 0;
      text-decoration: none;
    }
    &:hover{
      background-color: darken(#eee,10%);
      transition: background-color 0.25s ease;
      h2{
        text-decoration: none!important;
      }
    }
  }
}

.single-classroom{
  overflow-x: hidden;
  .full-screen-billboard{
    h2{
      font-size: 4.2rem;
    }

    h3{
      font-size: 2rem;
    }
    h2, h3{
      color: #FFFFFF;
      text-shadow: 2px 2px $primary_color;
    }
  }
  .full-screen-billboard{
    @media(max-width: 767px){
      padding-top: 15vh!important;
    }
  }
}
