// Variables
@import "_variables";

footer{
  font-family: $global_font;

  @media(max-width:767px){
    .col-md-4{
      margin-bottom:50px;
    }
  }
}
.dark-footer {
  float:left;
  width:100%;
  background: #222222;
}

.footer-info {
  padding: 25px 0;
}

footer p {
  font-size: 1em;
  margin-bottom: 0;
}

footer .social a {
  font-size: 1em;
  margin-left: 10px;
}

.light-footer {
  background: #f7f7f7;
}

.light-footer p {
  color: #FFF;
}



.dark-footer p {
  color: #FFF;
}

.color-footer {
  float:left;
  width:100%;
  background: $primary_color;
}

.color-footer p {
  color: #FFF;
}

.color-footer a {
  color: #ffffff;
}

.color-footer a:hover {
  color: #cccccc;
}

/* Footer Widgets */
.light-footer .widget-area {
  background: #ffffff;
}

.dark-footer .widget-area {
  background: black;
}

.color-footer .widget-area {
  background: $primary_color;
}

footer .widget-title {
  text-align: left;
  margin-bottom: 30px;
}

.dark-footer .widget-title h4 {
  font-size: 22px;
  color: #ffffff;
}

.color-footer .widget-title h4 {
  font-size: 2em;
  color: #ffffff;
}

footer .widget-title h4 {
  font-size: 2em;
  margin: 0;
}

footer .widget-area {
  font-size: 1em;
  padding: 50px 0;
}

footer .about-widget img {
  width: auto;

  margin: 0;
}

footer .blog-post-small {
  margin-top: 25px;
  padding-bottom: 30px;
}

footer .blog-post-small.second-post {
  margin-top: 25px;
  padding-bottom: 0;
}

footer .blog-post-small.first-post {
  border-bottom: 1px solid #cccccc;
  margin-top: 0;
}

footer .blog-posts-small img {
  max-width: 66px;
  margin: 0 10px 0 0;
  float: left;
}

footer .projects-widget img {
  max-width: 84px;
  float: left;
  margin: 0;
  border-right: 6px solid transparent;
  border-bottom: 6px solid transparent;
  display: inline-block;
}

footer .contact-widget .info {
  margin-bottom: 25px;
  width:100%;
  float:left;
}

footer .contact-widget i {
  font-size: .8em;
  margin-top: 5px;
  margin-right: 10px;
}

footer .contact-widget .social i {
  font-size: .8em;
}

footer .contact-widget span {
  margin-top: 3px;
}

footer .newsletter-widget .btn-newsletter {
  margin-left: 0 !important;
  padding: 10px 20px;
  background-color: #fff;
  color: #222;
}

footer #newsletter .form-control {
  margin-bottom: 25px;
  padding: 5px 20px;
  min-height: 42px;
}


/* / End Footer Widgets */
/* / End Footers */
