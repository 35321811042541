// Variables
@import "_variables";

/*.wrapper h1, .wrapper .h1 {
  font-size: 144px; }

.wrapper h2, .wrapper .h2 {
  font-size: 63px; }

.wrapper h3, .wrapper .h3 {
  font-size: 50px; }

.wrapper h4, .wrapper .h4 {
  font-size: 36px; }

.wrapper h5, .wrapper .h5 {
  font-size: 27px; }

.wrapper h6, .wrapper .h6 {
  font-size: 23px; }
.lead {
  margin-bottom: 28px;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.4; }
  */
.fade {
  opacity: 0;
  -webkit-transition: opacity .15s linear;
  -o-transition: opacity .15s linear;
  transition: opacity .15s linear; }
  .fade.in {
    opacity: 1; }

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden; }
  .embed-responsive .embed-responsive-item, .embed-responsive iframe, .embed-responsive embed, .embed-responsive object, .embed-responsive video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0; }

.embed-responsive-16by9 {
  padding-bottom: 56.25%; }

.embed-responsive-4by3 {
  padding-bottom: 75%; }

blockquote {
  border-left: 1px solid $primary_color;
  font-size: 27px;
  font-weight: lighter;
  font-family: 'Merriweather', Georgia, "Times New Roman", Times, serif;
  font-style: italic; }

.lead {
  font-size: 32px; }


/*
.wrapper-super {
  background-color: #fff; }

.wrapper-primary {
  background-color: #fff; }

.wrapper-contrast {
  background-color: $primary_color;
  color: #000000; }

.wrapper-secondary {
  background-color: #b9b9b9;
  color: #ffffff; }

.wrapper-dark {
  background-color: #222222;
  color: #eeeeee; }

.wrapper-hero {
  color: #ffffff;
  background-color: #eeeeee; }

a {
  font-weight: normal; }

.wrapper-navbar a {
  text-decoration: none;
  font-weight: lighter; }


a i:hover {
  background: none; }



.wrapper ul.nav.subnav li a:link {
  color: #000;
  text-decoration: none;
  padding: 5px;
  font-weight: normal; }

.wrapper.wrapper-trans ul.nav.subnav li a:link {
  color: #777777;
  padding: 0px; }
.wrapper.wrapper-trans ul.nav.subnav li a:hover {
  background: none;
  color: #eeeeee; }

.wrapper-trans {
  background: none;
  color: #555555; }

.wrapper-xl {
  font-size: 27px; }

.wrapper-sm, .wrapper-xs {
  font-size: 16px; }

.wrapper-bck-image-full {
  background-size: cover;
  background-position: center center; }

.wrapper-navbar-light {
  background-color: #fff; }

.wrapper-vid-bck {
  overflow: hidden;
  height: auto; }

.wrapper-bck-image-full::before {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 100%;
  background-color: rgba(34, 34, 34, 0.5);
  height: 100%; }

.wrapper-half-left::before {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  width: 50%;
  background-color: #eeeeee;
  color: #ffffff;
  height: 100%;
  background-position: center center; }

.wrapper-half-right::before {
  content: "";
  top: 0;
  right: 0;
  position: absolute;
  width: 50%;
  background-color: #eeeeee;
  color: #ffffff;
  height: 100%;
  background-position: center center; }

.wrapper-contrast.wrapper-half-left::before, .wrapper-contrast.wrapper-half-right::before {
  background-color: #222222; }

.wrapper-primary.wrapper-half-left::before, .wrapper-primary.wrapper-half-right::before {
  background-color: #eeeeee; }

#bck-img1.wrapper-half-left::before {
  background-image: url(../img/hero4.jpg);
  background-size: cover; }

#bck-img2.wrapper-half-right::before {
  background-image: url(../img/bck3.jpg);
  background-size: cover; }

#bck-img3.wrapper-half-left::before {
  background-image: url(../img/hero11.jpg);
  background-size: cover; }


.wrapper-big-top .container {
  padding-top: 150px;
  padding-bottom: 0px; }
*/
.video {
  position: absolute;
  z-index: 0;
  width: 100%;
  overflow: hidden; }

.video iframe {
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0px;
  bottom: 0px; }

.overlay {
  position: relative;
  top: 0;
  z-index: 1;
  width: 100%;
  padding-top: 150px;
  padding-bottom: 150px; }

.overlay.overlay-dark .container {
  background-color: rgba(34, 34, 34, 0.5); }
.overlay.overlay-dark .container p{
  color: #FFF;
}
#particle {
  position: absolute;
  z-index: 0;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%; }

.nav.subnav {
  border: 1px solid #eeeeee; }

.nav.subnav li {
  border-bottom: 1px solid #eeeeee;
  padding: 5px; }
/*
.wrapper-trans .nav.subnav {
  border: none; }

.wrapper-trans .nav.subnav li {
  border-bottom: none;
  padding: 0px; }

.wrapper-primary h1, .wrapper-primary h2, .wrapper-primary h3, .wrapper-primary h4, .wrapper-primary h5, .wrapper-primary h6 {
  color: #000; }
.wrapper-dark h1, .wrapper-dark h2, .wrapper-dark h3, .wrapper-dark  h4, .wrapper-dark h5, .wrapper-dark h6,  .wrapper-contrast h1, .wrapper-contrast h2, .wrapper-contrast h3, .wrapper-contrast  h4, .wrapper-contrast h5, .wrapper-contrast h6{
    color: #FFF; }
.wrapper-primary .btn-lg, .wrapper-primary .btn-group-lg > .btn {
  border: none; }
*/
h5, h6 {
  font-weight: bolder; }
/*
.wrapper h4:after {
  content: "";
  background-color: #777777;
  width: 20%;
  height: 1px;
  display: block;
  position: relative;
  margin-top: .4em; }

.wrapper-dark h4:after {
  background-color: $primary_color; }

.wrapper-contrast h4:after {
  background-color: #000000; }
*/
.text-right h4:after {
  margin-left: 80%; }

.text-center h4:after {
  margin: 0.25em auto 0 auto; }


/* GIVE */

#give.wrapper-contrast{
  color: #FFF;
}
