// Variables
@import "_variables";
#header-1 {
    border: none;
    margin: 0;
    padding: 5px 20px;
    z-index: 100;
    &.hide-show{
      position: fixed;
      top: 0;
      transition: top 0.2s ease-in-out;
    }
    &.nav-up{
      top: -75px!important;
    }
    &.navbar-primary{
      background-color: $primary_color;
    }
}

#header-1 nav {
    max-width: 1140px;
    margin: 0 auto
}
#header-1.navbar-black{
  background-color: #000;
}
#header-1.navbar-white {
    background-color: #FFF;
    border: 1px solid rgba(70, 76, 89, .1)
}

.header-nav-navigation ul{
  list-style-type: none;
}
.header-nav-navigation ul li{
  display: inline;
}
#header-1.navbar-white .header-nav-navigation-link {
    border: 1px solid #fff;
    background-color: #fff;
    color: #474B59
}
#header-1.navbar-transparent .header-nav-navigation-link{
  font-size: 18px;
  color: #ffffff;
}
#header-1.navbar-black .header-nav-navigation-link{
  font-size: 18px;
  color: #ffffff;
}
#header-1.navbar-white .header-nav-navigation-link{
  font-size: 18px;
  color: #000000;
}
#header-1.navbar-transparent .header-nav-navigation-link:hover{
  background-color: transparent;
}
#header-1.navbar-white .header-nav-navigation-link:hover, #header-1.navbar-white .header-nav-navigation-link.active {
    /*border-color: #F0F3F5;
    background: #F0F3F5;*/
    color: #474B59;
}

#header-1.navbar-white .header-nav-button {
    background: #fff;
}

#header-1.navbar-white .header-nav-button:hover {
  /*  background-color: #474B59*/
}

#header-1.navbar-white .header-nav-button:hover .header-nav-button-line {
    background-color: #fff;
}

#header-1.navbar-white .header-nav-button-line {
    background-color: #474B59;
}

#header-1.navbar-white .header-nav-navigation-link--outline {
    color: #286090;
    border-color: #286090;
}

#header-1.navbar-white .header-nav-navigation-link--outline:hover {
    color: #fff;
    background-color: #286090;
}

#header-1 .header-nav-button {
    margin-top: 15px;
}

#header-1 .header-nav-button:hover {
    background-color: #333;
}

#header-1 .header-nav-button-line {
    background-color: #fff;
}

#header-1 .header-nav-icon {
    font-size: 22px;
    opacity: .4;
    padding-left: 4px;
    position: relative;
    top: 2px;
}

#header-1 .header-nav-navigation {
    padding: 5px 0 0;
}

#header-1 .header-nav-navigation-item {
    display: inline;
}

#header-1 .header-nav-navigation-item.open .header-nav-navigation-link,
#header-1 .header-nav-navigation-item.open .header-nav-navigation-link i {
    color: #fff;
}

#header-1 .header-nav-navigation-link {
    text-decoration: none;
    /*border: 1px solid #474B59;
    background-color: #474B59;*/
    color: #c2c5cc;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 4px;
    padding: 10px 12px;
    margin-right: 2px;
    -webkit-transition-duration: 0;
    transition-duration: 0
}

#header-1 .header-nav-navigation-link:hover, #header-1 .header-nav-navigation-link.active {
    -webkit-transition-duration: 0;
    transition-duration: 0;
    /*background: #565B6B;
    border-color: #565B6B;*/
    color: #F0F3F5
}

#header-1 .header-nav-navigation-link:hover .header-nav-icon, #header-1 .header-nav-navigation-link.active .header-nav-icon {
    opacity: .7
}

#header-1 .header-nav-navigation-link:active {
    background-color: #393D48
}

#header-1 .header-nav-navigation-link--highlight {
    color: #286090
}

#header-1 .header-nav-navigation-link--highlight:hover {
    color: #8ad4d2
}

#header-1 .header-nav-navigation-link--outline {
    color: #286090;
    border-color: #286090
}

#header-1 .header-nav-navigation-link--outline:hover {
    color: #fff;
    background-color: #286090;
    border-color: #286090
}

#header-1 .header-nav-navigation-dropdown {
    background-color: #fff;
    border: none;
    border-radius: 3px;
    margin: 0
}

#header-1 .header-nav-navigation-dropdown:after {
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    content: "";
    display: inline-block;
    right: 20px;
    position: absolute;
    top: -6px
}

#header-1 .header-nav-navigation-dropdown-link {
    color: #474B59;
    padding: 6px 20px
}

#header-1 .header-nav-navigation-dropdown-link:hover {
    background-color: #e1e7eb
}

#header-1 .header-nav-logo {
    padding: 3px 0
}

.header-margin{
  margin-top: 75px;
}

@media(max-width:767px){
  .navbar-fixed-top .navbar-collapse{
    background:rgba(250,250,250,0.85);
  }
  .navbar-fixed-top .navbar-collapse li a{
    color: #000000;
  }
  #header-1 .header-nav-navigation {
      padding: 0px 0 0;
  }
  #header-1 .header-nav-button:hover{
    background-color: #000;
  }
  #header-1.navbar-transparent .header-nav-navigation-link{
    color: #333333;
  }
}
