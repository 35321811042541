.mt0 {
    margin-top: 0px !important;
}
.mt5 {
    margin-top: 5px !important;
}
.mt10 {
    margin-top: 10px !important;
}
.mt15 {
    margin-top: 15px !important;
}
.mt20 {
    margin-top: 20px !important;
}
.mt25 {
    margin-top: 25px !important;
}
.mt30 {
    margin-top: 30px !important;
}
.mt40 {
    margin-top: 40px !important;
}
.mt50 {
    margin-top: 50px !important;
}
.mt60 {
    margin-top: 60px !important;
}

.mt70 {
    margin-top: 70px !important;
}

.mt75 {
    margin-top: 75px !important;
}

.mt80 {
    margin-top: 80px !important;
}

.mb0 {
    margin-bottom: 0px !important;
}
.mb5 {
    margin-bottom: 5px !important;
}
.mb10 {
    margin-bottom: 10px !important;
}
.mb15 {
    margin-bottom: 15px !important;
}
.mb20 {
    margin-bottom: 20px !important;
}
.mb25 {
    margin-bottom: 25px !important;
}
.mb30 {
    margin-bottom: 30px !important;
}
.mb40 {
    margin-bottom: 40px !important;
}
.mb50 {
    margin-bottom: 50px !important;
}
.mb60 {
    margin-bottom: 60px !important;
}
.mb80 {
    margin-bottom: 80px !important;
}
.pt0 {
    padding-top: 0px !important;
}
.pt5 {
    padding-top: 5px !important;
}
.pt10 {
    padding-top: 10px !important;
}
.pt15 {
    padding-top: 15px !important;
}
.pt20 {
    padding-top: 20px !important;
}
.pt25 {
    padding-top: 25px !important;
}
.pt30 {
    padding-top: 30px !important;
}
.pt40 {
    padding-top: 40px !important;
}
.pt50 {
    padding-top: 50px !important;
}
.pt60 {
    padding-top: 60px !important;
}
.pt80 {
    padding-top: 80px !important;
}
.pt100{
  padding-top: 100px !important;
}

.pb0 {
    padding-bottom: 0px !important;
}
.pb5 {
    padding-bottom: 5px !important;
}
.pb10 {
    padding-bottom: 10px !important;
}
.pb15 {
    padding-bottom: 15px !important;
}
.pb20 {
    padding-bottom: 20px !important;
}
.pb25 {
    padding-bottom: 25px !important;
}
.pb30 {
    padding-bottom: 30px !important;
}
.pb40 {
    padding-bottom: 40px !important;
}
.pb50 {
    padding-bottom: 50px !important;
}
.pb60 {
    padding-bottom: 60px !important;
}
.pb80 {
    padding-bottom: 80px !important;
}
.pb100{
  padding-bottom: 100px !important;
}
.pl0{
  padding-left: 0px !important;
}
.pr0{
  padding-right: 0px !important;
}


.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}
