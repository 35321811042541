@import "./variables";

.subnav-container{
  width: 100%;
  text-align: center;
  display: block;
  background-color: #DDD;
}
.subnav{
  list-style-type: none;
  padding: 0px;
  display: inline-block;
  margin:auto;
  position: static;
  z-index: 1000;
}

.subnav.fixed{
    position: fixed;
}
.subnav li{
  display: inline;
  float: left;
}
.subnav li a{
    display: inline-block;
    position: relative;
    transition: .5s all;
    color: #333;
    font-weight: bold;
    padding: 0;
    font-size: .8em;
    line-height: 2.5em;
    height: 2em;
    padding-right: 2em;
    padding-left: 2em;
}

.subnav li.active a:after{
    content: "";
    width:0px;
    height:0px;
    border-left:15px solid transparent;
    border-right:15px solid transparent;
    border-top:15px solid #DDD;
    position:absolute;
    bottom:-15px;
    left: 50%;
    margin-left: -15px;
    z-index: 99;
}

.subnav li a:hover, .subnav li.active a{
    color: darken($primary_color,20%);
    text-decoration: none;
}
/* default style */
.selectnav { display: none; }

/* small screen */
@media screen and (max-width: 767px) {
  .subnav{
    li{
      width: 100%;
      display: block;
    }
  }
  /*.subnav{
    display: none;
  }
  .js #nav { display: none; }
  .js .selectnav {
    display: block;
    width: 70%;
    margin: 10px auto;
    background-color: #FFF;
    border: none;
    color: #000;
    box-shadow: none;
    font-weight: 400;
    font-size: 1.5rem;
  }*/
}
