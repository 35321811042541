// Variables
@import "_variables";

.btn:hover{
  transition: .25s;
}


.btn-not-first{
  margin-left: 1em;
}
@media(max-width:767px){
  .btn{
    margin-bottom: 1em;
  }
  .btn-not-first{
    margin-left: 0;
  }
}

.btn-lg{
  padding: 10px 35px;
}

.pull-center{
  text-align: center;
}


.btn {
  border-radius: 30px;
  border-width: 1px;
  word-wrap: break-word !important;
  white-space: normal !important;
}

.action-buttons .btn{
  margin-top: 0;
}
/*
.content-right .action-buttons .btn{
  float:right!important;
}
*/

.btn-transparent-white{
  background-color: transparent;
  border-color: #ffffff;
  color: #ffffff;
}
.btn-transparent-primary{
  background-color: transparent;
  border-color: $primary_color;
  color: $primary_color;
}

.btn-transparent-primary{
    border-color: darken($primary_color,10%);
    color: darken($primary_color,10%);
}

.btn-transparent-secondary{
  background-color: transparent;
  border-color: $secondary_color;
  color: $secondary_color;
}
.btn-transparent-white:hover, .btn-transparent-primary:hover, .btn-transparent-secondary:hover{
  background-color: rgba(255,255,255,0.15)!important;
}
.btn-transparent-white:hover{
  color: #ffffff!important;
}
.btn-transparent-primary:hover{
  color: $primary_color!important;
}
.btn-transparent-secondary:hover{
  color: $secondary_color!important;
}

.btn-primary-on-white{
  background-color: #ffffff;
  color: $primary_color !important;
  border-color: #ffffff;
}
.btn-secondary-on-white{
  background-color: #ffffff;
  color: $secondary_color;
  border-color: #ffffff;
}
.btn-secondary-on-white:active, .btn-secondary-on-white:focus, .btn-secondary-on-white:visited{
  color: $secondary_color;
}
.btn-primary-on-white:hover{
  background-color: darken(#ffffff,15%);
  border-color: darken(#ffffff,15%);
  color: $primary_color;
}
.btn-primary-on-white:active, .btn-primary-on-white:focus, .btn-primary-on-white:visited{
  color: $primary_color;
}
.btn-secondary-on-white{
  background-color: #f9f9f9;
  color: $secondary_color;
}

.btn-white-on-primary{
  background-color: $primary_color;
  color: #ffffff;
  border-color: $primary_color;
}
.btn-white-on-primary:active, .btn-white-on-primary:focus, .btn-white-on-primary:visited{
  color: #FFFFFF;
}


.btn-white-on-secondary{
  background-color: $secondary_color;
  color: #ffffff;
  border-color: $secondary_color;
}
.btn-white-on-secondary:active, .btn-white-on-secondary:focus, .btn-white-on-secondary:visited{
  color: #FFFFFF;
}
.btn-white-on-primary:hover{
  background-color: darken($primary_color, 15%);
  border-color: darken($primary_color, 15%);
  color: #ffffff;
}
.btn-white-on-secondary:hover{
  background-color: darken($secondary_color, 15%);
  border-color: darken($secondary_color, 15%);
  color: #ffffff;
}

.btn-text-only-primary{
  background-color: transparent;
  border:none;
  color: $primary_color;
}
.btn-text-only-secondary{
  background-color: transparent;
  border:none;
  color: $secondary_color;
}
.btn-text-only-white{
  background-color: transparent;
  border:none;
  color: #ffffff;
}
.btn-text-only-primary:hover{
  text-decoration: underline;
  color: $primary_color;
}
.btn-text-only-secondary:hover{
  text-decoration: underline;
  color: $secondary_color;
}
.btn-text-only-white:hover{
  text-decoration: underline;
  color: #ffffff;
}
