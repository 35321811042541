.icon-headline{
  text-align: center;
  padding: 0;
  margin: 0;
  position: relative;
}

.icon-headline.light .line-div{
    background-color: #FFF;
}

.icon-headline.light .icon{
  color: #FFF;
}

.icon-headline.dark .line-div{
    background-color: #444;
}

.icon-headline.dark .icon{
  color: #444;
}

.icon-headline .line-div{
  height: 4px;
  border-radius: 20px;
  vertical-align: top;
  margin-top: 28px;
  width: 40%;
}

.icon-headline .left-side{
  margin-left: 0%;
  margin-right: 2%;
  float: left;
}

.icon-headline .right-side{
  margin-right: 0%;
  margin-left: 2%;
  float: right;
}

.icon-headline .icon i{

}

.icon-headline .icon{
  width: 16%;
  text-align: center;
  height: 50px;
  font-size: 50px;
  line-height: 50px;
  float: left;
}

@media (max-width: 970px) {
  .icon-headline .left-side{
    margin-left: 3%;
    margin-right: 2%;
    float: left;
  }

  .icon-headline .right-side{
    margin-right: 3%;
    margin-left: 2%;
    float: right;
  }

  .icon-headline .line-div{
    width: 37%;
  }

}
