@import "./variables";

.villages{
  padding: 50px 15px;
  .row{
    @media(min-width: 992px){
      margin-bottom: 20px;
    }
  }
}
