.half-width-content-image-bg {
  background-size: cover!important;
  background-position: center center;

  .content-container{
    /*background-color: rgba(0,0,0,.4);*/
    background-color: #FFF;
    padding: 40px;
  }

  h2{
    margin-top: 0;
  }

  h3{
    font-size: 1em;
    /*color: #EEE;*/
  }
  .action-buttons .btn{
    margin-bottom: 1em;
    margin-right: 1em;
  }
  .action-buttons .btn-not-first{
    margin-left: 0;
    margin-right: 1em;
  }
  .content-right .action-buttons .btn-not-first{
    margin-right: 0em;
    margin-left: 1em;
  }

  .content-right{
    text-align: right;
  }
  @media(max-width:767px){
    overflow-x: hidden;
    .container{
      padding-left: 0;
      padding-right: 0;
    }
  }

}
