@import "_variables";
.grid-block{
  margin-bottom: 10px;

  h3{
    font-weight: bold;
    margin: 10px 0px 0px 0px;
  }

  a:hover{
    text-decoration-color: $body_text_color;
  }

}
