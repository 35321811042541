@import "./variables";

.event{
  h2{
    margin: 0;
    font-size: 1.5em;
    text-transform: uppercase;
  }
  h3{
    margin: 5px 0px;
    font-size: 1.5rem;
    color: #888888;
    text-transform: uppercase;
  }
}
#eventsSearch{
  margin-top: 25px;
  margin-bottom: 25px;
}
.search-event{
  margin-top: 10px;
  margin-bottom: 10px;
  h2{
    margin-top: 0px;
    @media(max-width: 767px){
      margin-top: 10px;
    }
  }
}
