.video-billboard {
  
  .pattern-overlay {
    background-color: rgba(71, 71, 71, 0.59);
    padding: 110px 0 32px;
    min-height: 496px;
    /* Incase of overlay problems just increase the min-height*/
  }
  h2, h3{
    text-align: left;
    color:#fff;
    font-family: "Montserrat", sans-serif!important;
  }

  h2{
    font-size: 3rem;
    font-family: 'Buenard', serif;
    font-weight:bold;
    text-transform: uppercase;
    margin: 40px auto 0px;
    text-shadow: 1px 1px 1px #000;
    -webkit-text-shadow: 1px 1px 1px #000;
    -moz-text-shadow: 1px 1px 1px #000;
  }

  h3{
    font-size: 1.7rem;
    font-weight:lighter;
    margin: 25px auto 15px;
  }

  .buttonBar{display:none;}

  .player {font-size: 1px;}
}
