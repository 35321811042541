// Variables
@import "_variables";

.base-footer{
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: $primary_color;
  color: #FFFFFF;
  h3, h4{
    color: #FFFFFF;
    font-weight: 600;
    text-transform: uppercase;
    &:after{
      display: block;
      height: 7px;
      border-bottom: 2px #FFF solid;
      width: 190px;
      content: '';
    }
  }
  .footer-nav{
    float: right;
    li{
    display: inline;
    float: left;
    margin-left: 10px;
      a{
        position: relative;
        display: block;
        padding: 0px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.41);
        padding-bottom: 0px;
        &:hover{
          background-color: transparent;
          text-decoration: none;
          border-color: $secondary_color;
        }
      }
    }
    list-style-type: none;
  }
  .footer-menu-container{
    h3{
      font-size: 1.2rem;
      margin-top: 0;
    }
    .footer-menu{
      padding-left: 0;
      list-style-type: none;
    }
  }
  h4{
    font-size: 1.4rem;
    margin-top: 0;

  }
  p, a{
    font-size: 1rem;
    color: #FFFFFF;
  }
}
