// Variables
@import "_variables";
.navbar-brand {
	@media(max-width: 992px){
		padding: 5px;
	}
	img{
		max-height: 50px;
		@media(max-width: 992px){
			max-height: 40px;
		}
	}
}
.navbar-default
{
	.navbar-nav>li>a {
		color: $primary_color;
		&:hover{
			color: darken($primary_color,10%);
		}
	}
}
